<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-6">
        <div class="form-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Search by Stake Currency"
            v-model="search"
            @keydown.enter="filterIt()"
          />
        </div>
      </div>
      <div class="col-4">
        <!-- <label for="exampleFormControlTextarea1" class="form-label">Plan Status</label> -->
        <multiselect
          v-model="filters.plan_status_json"
          :options="plan_status_opt"
          label="name"
          track-by="name"
          placeholder="Choose plan type..."
          @input="hitApiii(filters.plan_status_json)"
        ></multiselect>
      </div>
      <!-- <div class="col-2">
        <div class="form-group mb-3">
          <button type="button" class="btn btn-primary" @click="assignROI">Distribute ROI</button>
        </div>
      </div>-->
      <div class="col text-end">
        <!--unfilled-->
        <button
          type="button"
          class="btn btn-outline-primary btn-rounded"
          style="margin-right:10px"
          @click="assignROI"
        >Distribute ROI</button>

        <svg style="width:24px;height:24px" viewBox="0 0 24 24" v-b-modal.modal-filter>
          <path
            fill="currentColor"
            d="M3.47 5C3.25 5 3.04 5.08 2.87 5.21C2.43 5.55 2.35 6.18 2.69 6.61L2.69 6.62L7 12.14V18.05L10.64 21.71C11 22.1 11.66 22.1 12.05 21.71L12.05 21.71C12.44 21.32 12.44 20.69 12.06 20.3L9 17.22V11.45L4.27 5.39C4.08 5.14 3.78 5 3.47 5M21.62 3.22C21.43 3.08 21.22 3 21 3H7C6.78 3 6.57 3.08 6.38 3.22C5.95 3.56 5.87 4.19 6.21 4.62L11 10.75V15.87C10.96 16.16 11.06 16.47 11.29 16.7L15.3 20.71C15.69 21.1 16.32 21.1 16.71 20.71C16.94 20.5 17.04 20.18 17 19.88V10.75L21.79 4.62C22.13 4.19 22.05 3.56 21.62 3.22M15 10.05V17.58L13 15.58V10.06L9.04 5H18.96L15 10.05Z"
          />
        </svg>

        <!--filled-->
        <!-- <svg style="width:24px;height:24px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M3.46 5C3.25 5 3.04 5.08 2.87 5.21C2.43 5.55 2.35 6.18 2.69 6.61L2.69 6.62L8 13.42V19.41L10.29 21.71C10.68 22.1 11.32 22.1 11.71 21.71C12.1 21.32 12.1 20.68 11.71 20.29L10 18.59V12.73L4.27 5.39C4.08 5.14 3.78 5 3.46 5M16 12V19.88C16.04 20.18 15.94 20.5 15.71 20.71C15.32 21.1 14.69 21.1 14.3 20.71L12.29 18.7C12.06 18.47 11.96 18.16 12 17.87V12H11.97L6.21 4.62C5.87 4.19 5.95 3.56 6.38 3.22C6.57 3.08 6.78 3 7 3H21C21.22 3 21.43 3.08 21.62 3.22C22.05 3.56 22.13 4.19 21.79 4.62L16.03 12H16Z"
          />
        </svg>-->
      </div>
    </div>
    <div class="row">
      <div v-if="loading" class="no-data-box">
        <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>

      <div class="no-data-box" v-if="showData.length == 0 && !loading">
        <h6>No Data Found</h6>
      </div>
      <div
        class="col-xl-6 data-box"
        v-else-if="showData.length != 0 && !loading"
        v-for="(data, index) in showData"
        :key="index"
      >
        <div
          v-b-toggle.data
          v-b-toggle="'accordion-' + index"
          class="accordion_title"
          @click="stake_currency = data.stake_currency"
        >
          <div class="text-box">
            <div class="title">
              <img
                :src="data.stake_currency_image"
                style="border-radius: 50%;width: 20px;height: 20px;"
              />
              <img
                :src="data.reward_currency_image"
                style="border-radius: 50%;width: 20px;height: 20px;"
              />

              <p class="mb-0">{{ data.stake_currency }}-{{ data.reward_currency }}</p>
            </div>

            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="fill: #000">
                <path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z" />
              </svg>
            </div>
          </div>
        </div>

        <b-collapse :id="'accordion-' + index" accordion="my-accordion">
          <div class="mt-3" v-if="data.s_data.length != 0">
            <b-card-group deck>
              <b-card
                v-for="(data1, index1) in data.s_data"
                :key="index1"
                @click="
                    s_data_key =
                      data1.plan_type == 'flexible'
                        ? 'flexible-' + data1.maturity_days
                        : 'fixed-' + data1.maturity_days
                  "
                header-tag="header"
              >
                <!-- :header="
                    data1.plan_type == 'flexible'
                      ? 'Flexible'
                      : data1.maturity_days.toString() + ' days'
                "-->
                <template #header>
                  <div class="row">
                    <div class="col">
                      <span class>{{data1.plan_type}}</span>
                      <h6 class="mb-0">
                        {{data1.plan_type == 'flexible'
                        ? ''
                        : data1.maturity_days.toString() + ' days'}}
                      </h6>
                    </div>
                    <div class="col text-end">
                      <h5>
                        <span
                          class="badge text-dark"
                          :class="data1.is_expired ? 'bg-warning' : (data1.activate_status == 1) ? 'bg-success' : 'bg-danger'"
                        >{{data1.is_expired ? 'Expired' : (data1.activate_status == 1) ? 'Active' : 'Inactive'}}</span>
                      </h5>
                    </div>
                  </div>
                </template>
                <b-card-text>
                  <div class="check-text mb-2">
                    <div class="roi">
                      <span>ROI: {{ data1.roi_percentage }} %</span>
                    </div>

                    <div
                      class="form-check form-switch"
                      v-if="data1.is_new || data1.activate_status"
                    >
                      <label class="form-check-label" for="ee">Activate Status</label>

                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="ee"
                        @change="
                            onSubmit1(data1.activate_status, data1.id),
                              (data1.showActive = true)
                          "
                        v-model="data1.activate_status"
                      />

                      <!-- :disabled="!data1.activate_status" -->
                    </div>

                    <!-- <div v-else>{{ data1.activate_status == 1 ? "Active" : "Inactive" }}</div> -->
                  </div>

                  <div class="date-text mb-2">
                    <p class="mb-0 text-capitalize">Created Date</p>
                    <p class="mb-0">{{ formatDate(data1.created_at) }}</p>
                  </div>
                  <div class="date-text mb-2">
                    <p class="mb-0 text-capitalize">Start Date</p>
                    <p class="mb-0">{{ formatTimestampDate(data1.plan_start_date) }}</p>
                  </div>
                  <div class="date-text mb-2">
                    <p class="mb-0 text-capitalize">Expired Date</p>
                    <p class="mb-0">{{ formatTimestampDate(data1.plan_expiry_date) }}</p>
                  </div>

                  <div class="date-text mb-2">
                    <p class="mb-0 text-capitalize">Total Limit</p>
                    <p class="mb-0">{{ data1.pool_limit }}</p>
                  </div>
                  <div class="date-text mb-2">
                    <p class="mb-0 text-capitalize">Remaining Pool limit</p>
                    <p class="mb-0">{{ data1.remaining_pool_limit }}</p>
                  </div>

                  <div
                    class="date-text mb-2"
                    v-if="data1.activate_status && data1.plan_type == 'fixed'"
                  >
                    <p class="mb-0 text-capitalize">Auto Staking</p>
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        @change="
                            onSubmit2(data1.autostake_enable, data1.id)"
                        v-model="data1.autostake_enable"
                      />
                    </div>
                  </div>
                  <div
                    class="date-text mb-2"
                    v-if="data1.is_expired && data1.plan_type == 'fixed'"
                  >

                   <!-- <p class="mb-0">
                      <button
                        @click="CallUserautoApi(data1.id)"
                        class="btn btn-primary"
                      >Deactivate User Autostake</button>
                    </p> -->


                    <p class="mb-0 text-capitalize">Deactivate User Autostake</p>
                    <div class="form-check form-switch">
                      <!-- <input
                        class="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        @change="CallUserautoApi(data1.id)"
                      /> -->
                      <button type="button" class="btn btn-outline-primary btn-rounded btn-sm" @click="CallUserautoApi(data1.id)" data-mdb-ripple-color="dark">Deactivate</button>
                      <!-- <i class="fa fa-ban" aria-hidden="true"  @click="CallUserautoApi(data1.id)" ></i> -->
                    </div>
                  </div>
                  <div class="progress-text-box mb-2">
                    <div class="progress-percentage">
                      <p class="mb-0">
                        Remaining Pool limit: {{
                        perCen(data1.pool_limit,data1.remaining_pool_limit) + "%"
                        }}
                      </p>
                    </div>

                    <div class="model-links text-right">
                      <router-link class="card-link" :to="`/user_stake/list/` + data1.id">
                        <i class="fas fa-users text-primary action"></i>
                      </router-link>

                      <a
                        href="#"
                        class="card-link"
                        variant="primary"
                        v-b-modal.modal-center
                        @click="(isEdit = true), formOpen(data1)"
                      >
                        <!--VIEW-->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          style="
                              fill: #556ee6;
                              cursor: pointer;
                              transform: ;
                              msfilter: ;
                            "
                        >
                          <path
                            d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 11c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z"
                          />
                          <path d="M12 10c-1.084 0-2 .916-2 2s.916 2 2 2 2-.916 2-2-.916-2-2-2z" />
                        </svg>
                      </a>
                      <a
                        href="#"
                        class="card-link"
                        variant="primary"
                        v-b-modal.modal-center
                        @click="(isEdit = false), formOpen(data1)"
                        v-if="data1.is_new"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          style="
                              fill: #556ee6;
                              cursor: pointer;
                              transform: ;
                              msfilter: ;
                            "
                        >
                          <path
                            d="m7 17.013 4.413-.015 9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583v4.43zM18.045 4.458l1.589 1.583-1.597 1.582-1.586-1.585 1.594-1.58zM9 13.417l6.03-5.973 1.586 1.586-6.029 5.971L9 15.006v-1.589z"
                          />
                          <path
                            d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2z"
                          />
                        </svg>
                      </a>
                      <!--   v-if=" !data1.activate_status || data1.is_expired"-->
                      <a
                        href="#"
                        class="card-link"
                        variant="primary"
                        @click="dltApi(data1.id)"
                        v-if="data1.is_new"
                      >
                        <i class="far fa-trash-alt text-danger action"></i>
                      </a>
                    </div>
                  </div>

                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      aria-valuenow="70"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style="background-color: green; margin: 0; color: black"
                      :style="{
                          width:
                            perCen(
                              data1.pool_limit,
                              data1.remaining_pool_limit
                            ) + '%',
                        }"
                    >
                      <span class="sr-only">
                        {{
                        perCen(data1.pool_limit, data1.remaining_pool_limit) +
                        "%"
                        }}
                      </span>
                    </div>
                  </div>
                  <!-- <div class="date-text mb-2 justify-content-center mt-3">
                    <p class="mb-0">
                      <button
                        @click="CallUserautoApi(data1.id)"
                        class="btn btn-primary"
                      >Deactivate User Autostake</button>
                    </p>
                  </div> -->
                </b-card-text>
              </b-card>
            </b-card-group>
          </div>
        </b-collapse>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
    </div>

    <!-- Modal for assigning token to vendor -->
    <b-modal
      id="modal-center"
      size="xl"
      centered
      :title="getTitle()"
      title-class="font-18"
      hide-footer
      class="padding_modal"
      ref="vuemodal"
    >
      <!-- @hidden="onHidden()" -->
      <b-form @submit.prevent="onSubmit()">
        <b-row>
          <!-- <b-col col lg="4">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Title</label>
              <input type="text" class="form-control" :disabled="isEdit" v-model="form.title" />

              <div
                class="error mt-1"
                v-if="submitted && $v.form.title.$error"
              >{{ $v.form.title.$errors[0].$message }}</div>
            </div>
          </b-col>-->

          <b-col col lg="4">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Description</label>
              <input type="text" class="form-control" :disabled="isEdit" v-model="form.description" />
              <!-- <div
                class="error mt-1"
                v-if="submitted && $v.form.description.$error"
              >{{ $v.form.description.$errors[0].$message }}</div>-->
            </div>
          </b-col>

          <!-- <b-col col lg="4">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Stake Currency</label>
              <input
                type="text"
                class="form-control"
                :disabled="isEdit"
                v-model="form.stake_currency"
              />
            </div>
          </b-col>

          <b-col col lg="4">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Reward Currency</label>
              <input
                type="text"
                class="form-control"
                :disabled="isEdit"
                v-model="form.reward_currency"
              />
            </div>
          </b-col>-->

          <b-col col lg="4" v-if="form.maturity_days != 'Flexible'">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Maturity Days</label>
              <input
                type="text"
                class="form-control"
                :disabled="isEdit"
                v-model="form.maturity_days"
                onpaste="return false;"
              />

              <div
                class="error mt-1"
                v-if="submitted && $v.form.maturity_days.$error"
              >{{ $v.form.maturity_days.$errors[0].$message }}</div>
            </div>
          </b-col>

          <!-- <b-col col lg="4">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Plan Type</label>
              <input type="text" class="form-control" :disabled="isEdit" v-model="form.plan_type" />
            </div>
          </b-col>-->
          <b-col col lg="4">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Min Stake Amount</label>
              <input
                type="text"
                class="form-control"
                :disabled="isEdit"
                v-model="form.min_stake_amount"
                onpaste="return false;"
              />
              <div
                class="error mt-1"
                v-if="submitted && $v.form.min_stake_amount.$error"
              >{{ $v.form.min_stake_amount.$errors[0].$message }}</div>
            </div>
          </b-col>
          <b-col col lg="4">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Max Stake Amount</label>
              <input
                type="text"
                class="form-control"
                :disabled="isEdit"
                v-model="form.max_stake_amount"
                onpaste="return false;"
              />
              <div
                class="error mt-1"
                v-if="submitted && $v.form.max_stake_amount.$error"
              >{{ $v.form.max_stake_amount.$errors[0].$message }}</div>
            </div>
          </b-col>

          <b-col col lg="4">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Plan Start Date</label>
              <input
                type="text"
                class="form-control"
                v-if="isEdit"
                readonly
                v-model="form.plan_start_date"
              />

              <input
                v-else
                type="date"
                class="form-control"
                v-model="form.plan_start_date"
                :min="new Date().toISOString().split('T')[0]"
              />
              <div
                class="error mt-1"
                v-if="submitted && $v.form.plan_start_date.$error"
              >{{ $v.form.plan_start_date.$errors[0].$message }}</div>
            </div>
          </b-col>

          <b-col col lg="4">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Plan Expiry Date</label>
              <input
                type="text"
                class="form-control"
                v-if="isEdit"
                readonly
                v-model="form.plan_expiry_date"
              />
              <input
                type="date"
                v-else
                class="form-control"
                v-model="form.plan_expiry_date"
                :min="
                  form.plan_start_date
                    ? form.plan_start_date
                    : new Date().toISOString().split('T')[0]
                "
              />
              <!-- <input v-else type="text" class="form-control" :disabled="isEdit" value="-" /> -->
              <div
                class="error mt-1"
                v-if="submitted && $v.form.plan_expiry_date.$error"
              >{{ $v.form.plan_expiry_date.$errors[0].$message }}</div>
            </div>
          </b-col>
          <!-- <b-col col lg="4">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Plan Expiry Days</label>
              <input
                type="text"
                class="form-control"
                :disabled="isEdit"
                v-model="form.plan_expiry_date"
              />
            </div>
          </b-col>-->

          <b-col col lg="3">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Total Limit</label>
              <input
                type="text"
                class="form-control"
                :disabled="isEdit"
                v-model="form.pool_limit"
                onpaste="return false;"
              />
              <div
                class="error mt-1"
                v-if="submitted && $v.form.pool_limit.$error"
              >{{ $v.form.pool_limit.$errors[0].$message }}</div>
            </div>
          </b-col>
          <!-- <b-col col lg="4">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">ROI Interval</label>
              <input
                type="text"
                class="form-control"
                v-if="isEdit"
                readonly
                :placeholder="
                  form.roi_interval.name
                    ? form.roi_interval.name
                    : form.roi_interval
                "
              />

              <multiselect
                v-else
                v-model="form.roi_interval"
                :options="ROI_opt"
                label="name"
                track-by="value"
                placeholder="Choose..."
              ></multiselect>

              <div
                class="error mt-1"
                v-if="submitted && $v.form.roi_interval.$error"
              >{{ $v.form.roi_interval.$errors[0].$message }}</div>
            </div>
          </b-col>-->
          <b-col col lg="3">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">ROI Percentage</label>
              <input
                type="text"
                class="form-control"
                :disabled="isEdit"
                v-model="form.roi_percentage"
                onpaste="return false;"
              />
              <div
                class="error mt-1"
                v-if="submitted && $v.form.roi_percentage.$error"
              >{{ $v.form.roi_percentage.$errors[0].$message }}</div>
            </div>
          </b-col>
          <b-col col lg="3">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Per User Limit</label>
              <!-- v-if="form.per_user_limit" -->
              <input
                type="text"
                class="form-control"
                :disabled="isEdit"
                v-model="form.per_user_limit"
                onpaste="return false;"
              />

              <!-- value="-" -->
              <!-- <input v-else type="text" class="form-control" :disabled="isEdit" value="-" /> -->
              <div
                class="error mt-1"
                v-if="submitted && $v.form.per_user_limit.$error"
              >{{ $v.form.per_user_limit.$errors[0].$message }}</div>
            </div>
          </b-col>
          <b-col col lg="3" v-if="form.plan_type == 'fixed' ">
            <div class="form-group mb-3">
              <label class="form-label" for="flexSwitchCheckDefault">Auto Stake Enable</label>

              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                v-model="form.autostake_enable"
                v-if="!isEdit"
              />

              <div v-else>
                <!-- <label class="form-label" for="flexSwitchCheckDefault">Auto Stake</label> -->
                <span>{{form.autostake_enable ? 'Active' : 'Inactive'}}</span>
              </div>

              <!-- :disabled="!data1.activate_status" -->
            </div>
          </b-col>

          <!-- <b-col col lg="4">
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Auto Stake Enable</label>
                        <input v-if="form.extra" type="text" class="form-control" readonly v-model="form.extra" value="-"/>
                         <input v-else type="text" class="form-control" readonly  value="-"/>
                    </div>
          </b-col>-->
          <!-- <b-col col lg="4">
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Extra</label>
                        <input v-if="form.extra" type="text" class="form-control" readonly v-model="form.extra" value="-"/>
                         <input v-else type="text" class="form-control" readonly  value="-"/>
                    </div>
          </b-col>-->

          <!-- <b-col col lg="4">
            <div class="mb-3">
               <label for="exampleFormControlTextarea1" class="form-label">Select Priority</label>
              <input type="text" class="form-control" readonly v-model="form." />
            </div>
          </b-col>-->
          <!-- <hr />
          <h5>Activate Plan</h5>
          <b-col col lg="12">
            <div class="mb-3" v-if="statusDiv">
              <label for="exampleFormControlTextarea1" class="form-label">Plan Status</label>
              <multiselect
                v-model="plan_status"
                :options="selectStatus"
                label="name"
                track-by="name"
                placeholder="Choose..."
              ></multiselect>
              <span class="text-danger" v-if="!plan_status && submitted">Status is required</span>
            </div>
            <div class="mb-3" v-else>
              <span class="text-danger">If It Once Expired Then Plan Status Can't Be Active</span>
            </div>
          </b-col>-->
          <!-- <div v-if="plan_status.value==1">
                    <span class="text-warning"><b>!Warning</b><br /> If It Once Activated Then Active Status Can't Be Changed</span>
          </div>-->
          <!---submit button-->
          <div class="col-12 mt-5 text-center" v-if="!isEdit">
            <div class="form-group btn-submit">
              <div class="spinner-border" role="status" v-if="this.formloading">
                <span class="visually-hidden">Loading...</span>
              </div>
              <button type="submit" class="btn btn-primary" id="submit" v-if="!formloading">Submit</button>
            </div>
          </div>
          <!---submit button-->
        </b-row>
      </b-form>
    </b-modal>

    <!-- end row -->

    <b-modal
      id="modal-filter"
      size="xl"
      centered
      title="Filters"
      title-class="font-18"
      hide-footer
      class="padding_modal"
      ref="vuemodal"
    >
      <!-- @hidden="onHidden()" -->
      <b-form @submit.prevent="filterIt()">
        <b-row>
          <!-- <b-col col lg="6">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Plan Type</label>
              <multiselect
                v-model="filters.plan_json"
                :options="plan_type_opt"
                label="name"
                track-by="name"
                placeholder="Choose..."
              ></multiselect>
            </div>
          </b-col>

          <b-col col lg="6">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Plan Status</label>
              <multiselect
                v-model="filters.plan_status_json"
                :options="plan_status_opt"
                label="name"
                track-by="name"
                placeholder="Choose..."
              ></multiselect>
            </div>
          </b-col>-->

          <b-col col lg="4">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Plan Type</label>
              <multiselect
                v-model="filters.plan_json"
                :options="plan_type_opt"
                label="name"
                track-by="name"
                placeholder="Choose..."
              ></multiselect>
            </div>
          </b-col>

          <b-col col lg="4">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Plan Start Date</label>
              <input type="date" step="1" class="form-control" v-model="filters.start_date.val" />
            </div>
          </b-col>

          <b-col col lg="4">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">Plan End Date</label>

              <input type="date" step="1" class="form-control" v-model="filters.end_date.val" />
            </div>
          </b-col>

          <div class="col-12 mt-5 text-end">
            <div class="form-group btn-submit">
              <button type="submit" class="btn btn-primary" id="submit">Apply</button>
              <button
                type="reset"
                class="btn btn-primary"
                style="margin-left:15px;margin-right:5px"
                id="submit"
                @click="resetFilter()"
              >Reset</button>
            </div>
          </div>
          <!---submit button-->
        </b-row>
      </b-form>
    </b-modal>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import simplebar from "simplebar-vue";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";
import moment from "moment";
import {
  required,
  // minLength,
  // maxLength,
  // helpers,
  minValue,
  requiredIf
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Multiselect from "vue-multiselect";
import _ from "lodash";

export default {
  page: {
    title: "Staking List",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    PageHeader,
    // simplebar,
    Multiselect
  },
  setup() {
    return {
      $v: useVuelidate()
    };
  },
  data() {
    return {
      statusDiv: true,
      title: "Staking List",
      items: [
        {
          text: "Staking "
          // href: "/staking/list",
        },
        {
          text: "Staking List",
          active: true
        }
      ],
      submitted: false,
      loading: false,
      formloading: false,
      selectStatus: [
        { name: "ON", value: 1 },
        { name: "OFF", value: 0 }
      ],
      form: {},
      totalRows: 0,
      currentRowData: "",
      currentPage: 1,
      perPage: 14,
      sortBy: "value",
      sortDesc: false,
      plan_status: { name: "ON", value: 1 },
      showData: [],
      stake_currency: "",
      s_data_key: "",
      isEdit: false,
      ROI_opt: [
        {
          value: "D",
          name: "Daily"
        },
        {
          value: "M",
          name: "Monthly"
        },
        {
          value: "Y",
          name: "Year"
        }
      ],
      // search_string: "&plan_status=upcoming",
      search_string: "",
      search: "",
      plan_type_opt: [
        { name: "Fixed", val: "fixed", col: "plan_type" },
        { name: "Flexible", val: "flexible", col: "plan_type" }
      ],
      plan_status_opt: [
        { name: "Inactive", val: "upcoming", col: "plan_status" },
        // { name: "Choose plan type...", val: "default", col: "" },
        { name: "Running", val: "running", col: "plan_status" },
        { name: "Expired", val: "expired", col: "plan_status" }
      ],
      filters: {
        plan_json: { val: "", col: "plan_type" },
        plan_status_json: { name: "", val: "", col: "plan_status" },
        start_date: { val: "", col: "start_date" },
        end_date: { val: "", col: "end_date" }
      },
      filters_show: "",
      assignRoi: ""
    };
  },
  validations() {
    return {
      form: {
        // title: {
        //   required,
        //   minLength: minLength(3),
        //   maxLength: maxLength(200)
        // },
        // description: {
        //   required
        //   // minLength: minLength(20),
        //   // maxLength: maxLength(500)
        // },
        maturity_days: {
          required: requiredIf(() => {
            return this.form.maturity_days != "Flexible";
          })
        },
        roi_percentage: {
          required
        },
        per_user_limit: {
          required,
          minValue: minValue(this.form.min_stake_amount)
        },
        // roi_interval: {
        //   required
        // },
        min_stake_amount: {
          required
        },
        max_stake_amount: {
          required,
          minValue: minValue(this.form.min_stake_amount)
        },
        plan_expiry_date: {
          required
          //
        },
        plan_start_date: {
          required
          //
        },
        pool_limit: {
          required
          //
        }
      }
    };
  },
  mounted() {
    this.myProvider();
  },
  watch: {
    currentPage: function() {
      this.myProvider();
    }
  },
  methods: {
     async CallUserautoApi(plan_id){

      Swal.fire({
        title: "Deactivate User Autostake?",
       
        showCancelButton: true,
        //  confirmButtonColor: '#3085d6',
        //  cancelButtonColor: '#d33',
        confirmButtonText: "Proceed",
        cancelButtonText: "Cancel",
        closeButton: true
        //  onOpen: function () {
        //    document.getElementById('#payment_day').datetimepicker({
        //      format: 'L'
        //    });
        //  },
      }).then(result => {
        if (result.isConfirmed) {
            ApiClass.updatenodeRequest(`staking/deactivate_user_autostake/${plan_id}`,true).then(result => {

                     if (result.data.status_code == "1") {
          
          Swal.fire({
            position: "center",
            icon: "success",
            title: result.data.message,
            showConfirmButton: false,
            timer: 2000
          });
          
        } else {
         
          Swal.fire({
            position: "center",
            icon: "error",
            title: result.data.message,
            showConfirmButton: false,
            timer: 2000
          });
        }
            })
      
        }
      })
    },
    assignROI() {
      // let datePicker = document.createElement("input");
      // datePicker.type = "date";
      Swal.fire({
        title: "Distribute Missing ROI",
        html:
          '<input type="date" name="payment_day" class="datetimepicker-input swal2-input" autocomplete="off" id="payment_day">',
        showCancelButton: true,
        //  confirmButtonColor: '#3085d6',
        //  cancelButtonColor: '#d33',
        confirmButtonText: "Distribute",
        cancelButtonText: "Cancel",
        closeButton: true
        //  onOpen: function () {
        //    document.getElementById('#payment_day').datetimepicker({
        //      format: 'L'
        //    });
        //  },
      }).then(result => {
        if (result.isConfirmed) {
          this.assignRoi = document.getElementById("payment_day").value;
          this.assignROI1();
          // let val =   document.getElementById('payment_day').target.value;
          // console.log({val});
        }
      });
      // });
      //       Swal.fire({
      //   content: {
      //     element: "input",
      //     attributes: {
      //       placeholder: "Type your password",
      //       type: "password",
      //     },
      //   },
      // });

      // swal({
      //   content: {
      //     element: "input",
      //     attributes: {
      //       placeholder: "Type your password",
      //       type: "password",
      //     },
      //   },
      // });

      // Swal.fire({
      //   // title: "Distribute Missing ROI!",
      //   // text: "Enter Date:",
      //   // input: "text",
      //   content: "datePicker",

      //   showCancelButton: true
      // }).then(result => {
      //   if (result.value) {
      //     console.log("Result: " + result.value);
      //   }
      // });
    },
    async assignROI1() {
      await ApiClass.getNodeRequest(
        "staking/force-income?date=" + this.assignRoi,
        true
      ).then(result => {
        // console.log('res=', res);

        if (result.data.status_code == "1") {
          this.formloading = false;
          this.submitted = false;
          Swal.fire({
            position: "center",
            icon: "success",
            title: result.data.message,
            showConfirmButton: false,
            timer: 2000
          });
          // this.$refs.table.refresh();
          this.myProvider();
          // this.$root.$emit("bv::hide::modal", "modal-center");
        } else {
          this.formloading = false;
          this.submitted = false;
          Swal.fire({
            position: "center",
            icon: "error",
            title: result.data.message,
            showConfirmButton: false,
            timer: 2000
          });
        }
      });
      // http://192.168.11.223:4201/staking/force-income?date=2022-01-01s
    },
    filterIt() {
      this.search_string = "";

      if (this.search) {
        this.search_string += "&search=" + this.search;
      }
      let isFilter = false;
      console.log("filterssss", this.filters);

      isFilter = _.forEach(this.filters, n => {
        if (n.val) return true;
      });

      if (isFilter) {
        _.forEach(this.filters, n => {
          this.search_string += "&" + n.col + "=" + n.val;
        });
        this.$root.$emit("bv::hide::modal", "modal-filter");
      }
      // console.log(this.search_string);
      this.search_string ? this.myProvider() : "";
    },
    resetFilter() {
      this.filters = {
        plan_json: "",
        // plan_status_json: "",
        start_date: { val: "", col: "start_date" },
        end_date: { val: "", col: "end_date" }
      };
    },
    hitApiii(p_json) {
      this.search_string = "&" + p_json.col + "=" + p_json.val;
      this.myProvider();
    },
    // modalSearh() {
    //   // console.log(this.filters);

    //   _.forEach(this.filters, n => {
    //     this.search_string += "&" + n.col + "=" + n.val;
    //   });

    //   // console.log(this.search_string);

    //   // if (str && col) {
    //   //   this.search_string += "&" + col + "=" + str;
    //   // } else {
    //   //   this.search_string = "";
    //   // }
    //   this.myProvider();
    //   this.$root.$emit("bv::hide::modal", "modal-filter");
    // },
    getTitle() {
      let txt = this.form.stake_currency
        ? this.form.stake_currency +
          "-" +
          this.form.reward_currency +
          "-" +
          this.form?.plan_type.toUpperCase() +
          ")"
        : "";
      return this.isEdit
        ? "View Full Plan (" + txt
        : "Update Stake Plan (" + txt;
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    formatTimestampDate(date) {
      return moment(parseFloat(date)).format("DD-MM-YYYY HH:mm");
    },
    perCen(total, num) {
      let cal = (num / total) * 100;
      return cal.toString().indexOf(".") == -1
        ? cal
        : parseFloat(cal).toFixed(2);
    },

    onHidden() {
      this.$refs.table.refresh();
    },
    formOpen(data) {
      this.form = {};
      // console.log({ data }, "dfjhdfuhuhfidhfuisdhfudhsfu");
      data.maturity_days =
        data.maturity_days == -1 ? "Flexible" : data.maturity_days;
      this.form = _.cloneDeep(data);
      this.form.roi_interval = "D";
      this.form.autostake_enable = data.autostake_enable;
      // this.form.roi_interval = _.find(this.ROI_opt, {
      //   value: data.roi_interval
      // });
      // console.log({ eeee: this.form.roi_interval }, data.roi_interval);
      this.form.plan_start_date = new Date(
        parseFloat(this.form.plan_start_date)
      )
        .toISOString()
        .substring(0, 10);
      this.form.plan_expiry_date = new Date(
        parseFloat(this.form.plan_expiry_date)
      )
        .toISOString()
        .substring(0, 10);
    },
    async onSubmit2(val, id) {
      // console.log({ val, id });
      // event.preventDefault();
      this.submitted = true;
      if (this.plan_status != null) {
        this.formloading = true;

        // 192.168.11.246:4201/staking/update_stake_plan/:plan_id
        val = val ? 1 : 0;
        var result = await ApiClass.updatenodeRequest(
          "staking/update_auto_stake/" + id + "/" + val,
          true
        );
        // console.log("here=", result);

        if (result.data.status_code == "1") {
          this.formloading = false;
          this.submitted = false;
          Swal.fire({
            position: "center",
            icon: "success",
            title: result.data.message,
            showConfirmButton: false,
            timer: 2000
          });
          // this.$refs.table.refresh();
          this.myProvider();
          // this.$root.$emit("bv::hide::modal", "modal-center");
        } else {
          this.formloading = false;
          this.submitted = false;
          Swal.fire({
            position: "center",
            icon: "error",
            title: result.data.message,
            showConfirmButton: false,
            timer: 2000
          });
        }
        this.myProvider();
      }
    },
    async onSubmit1(val, id) {
      console.log({ val, id });
      if (!val) {
        return Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Deactivate it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true
        }).then(result => {
          if (result.isConfirmed) {
            this.apiHit(val, id);
            // this.myProvider();
            // this.$refs.table.refresh();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            Swal.fire("Cancelled", "Plan is still active. :)", "error");
            this.myProvider();
          }
        });
        // return;
      }

      this.apiHit(val, id);
    },
    async apiHit(val, id) {
      // event.preventDefault();
      this.submitted = true;
      if (this.plan_status != null) {
        this.formloading = true;
        // console.log("hihihi");
        var param = {
          plan_id: id,
          plan_status: val
        };

        // 192.168.11.246:4201/staking/update_stake_plan/:plan_id

        var result = await ApiClass.updatenodeRequest(
          "staking/activation",
          true,
          param
        );
        // console.log("here=", result);

        if (result.data.status_code == "1") {
          this.formloading = false;
          this.submitted = false;
          Swal.fire({
            position: "center",
            icon: "success",
            title: result.data.message,
            showConfirmButton: false,
            timer: 2000
          });
          // this.$refs.table.refresh();
          // this.search_string = '&plan_status=running';
          // this.myProvider();
          this.$root.$emit("bv::hide::modal", "modal-center");
        } else {
          this.formloading = false;
          this.submitted = false;
          Swal.fire({
            position: "center",
            icon: "error",
            title: result.data.message,
            showConfirmButton: false,
            timer: 2000
          });
        }
        this.myProvider();
      }
    },
    async onSubmit() {
      // console.log({ form: this.form });

      // event.preventDefault();

      this.submitted = true;

      const result1 = await this.$v.form.$validate();
      // console.log(result1, this.$v.form);
      if (!result1) {
        return;
      }
      // if (this.plan_status != null) {
      this.form.maturity_days =
        this.form.maturity_days == "Flexible" ? -1 : this.form.maturity_days;
      // this.form.roi_interval = this.form.roi_interval.value
      //   ? this.form.roi_interval.value
      //   : this.form.roi_interval;
      this.form.roi_interval = "D";

      delete this.form.activate_status;
      this.formloading = true;
      // console.log("hihihi");

      // 192.168.11.246:4201/staking/update_stake_plan/:plan_id

      var result = await ApiClass.updatenodeRequest(
        "staking/update_stake_plan/" + this.form.id,
        true,
        this.form
      );
      // console.log("here=", result);

      if (result.data.status_code == "1") {
        this.formloading = false;
        this.submitted = false;
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 2000
        });
        // this.$refs.table.refresh();
        this.myProvider();
        this.$root.$emit("bv::hide::modal", "modal-center");
      } else {
        this.formloading = false;
        this.submitted = false;
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 2000
        });
      }
      // }
    },
    async myProvider() {
      // console.log('in function');
      this.loading = true;
      await ApiClass.getNodeRequest(
        "staking/get?all=true" +
          this.search_string +
          "&per_page=" +
          this.perPage +
          "&page=" +
          this.currentPage,
        true
      ).then(res => {
        // console.log('res=', res);

        if (res.data.status_code == 1) {
          this.loading = false;
          // const items = res.data.data ? res.data.data : [];
          this.showData = res.data.data.data;
          this.currentPage = res.data.data ? res.data.data.current_page : "";
          this.totalRows = res.data.data ? res.data.data.total : "";
          // callback(items);
        }
      });
      // .bind(this);
    },
    onclose() {
      this.$root.$emit("bv::hide::modal", "modal-center");
    },
    plan_exp_date(date) {
      let current_date = new Date().getTime();
      if (date < current_date) {
        return "Expired";
      }
      return "Not Expired";
    },
    async dltApi(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          this.dltApi1(id);
          // this.myProvider();
          // this.$refs.table.refresh();
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire("Cancelled", "Plan is still there. :)", "error");
          this.myProvider();
        }
      });
    },
    async dltApi1(id) {
      let response = await ApiClass.deletenodeRequest(
        "staking/delete_stake_plan/" + id,
        true
      );
      // Swal.fire("Deleted!", "Page has been deleted.", "success");

      if (response.data.status_code == 1) {
        Swal.fire("Deleted!", response.data.message, "success");
      } else {
        Swal.fire("Error!", response.data.message, "error");
      }
      this.myProvider();
    }
  }
};
</script>

<style scoped>
.accordion_title {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 15px 15px;
  border-radius: 5px;
  -webkit-box-shadow: rgb(0 0 0 / 2%) 0px 1px 3px 0px,
    rgb(27 31 35 / 15%) 0px 0px 0px 1px;
  box-shadow: rgb(0 0 0 / 2%) 0px 1px 3px 0px,
    rgb(27 31 35 / 15%) 0px 0px 0px 1px;
}

.text-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.text-box .title p {
  font-weight: 600;
}

.check-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.date-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.progress-text-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.card-header {
  background-color: #ccc;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
  color: #000;
}

.no-data-box {
  min-height: 80vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
</style>
